import styled from "@emotion/styled";
import React, { useReducer } from "react";
import PropTypes from "prop-types";
import { snakeCase, reduce } from "lodash";

import IconButton from "~/components/buttons/IconButton";

import FaIcon from "~/components/FaIcon";
import FilterListItem from "~/components/FilterListItem";

import {
    getFilterChildren,
    getFilterOptionsToDisplay,
    getShowLimitButtons,
} from "~/utilities/filters";

import { sortLanguageLinks } from "~/context/TranslationContext";

import useStringTranslation from "~/hooks/translations/useStringTranslation";

export const FilterWrapper = styled.div`
    margin-bottom: 25px;
    position: relative;
`;

export const FilterTitle = styled.div`
    font-weight: 700;
    margin-bottom: 10px;
    display: ${({ alwaysVisible }) => (alwaysVisible ? `block` : `none`)};

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
        display: block;
    }
`;

const MobileFilterToggle = styled.button`
    font-weight: 700;
    text-align: left;
    border: ${({ theme }) => `1px solid ${theme.palette.black}`};
    padding: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
        display: none;
    }
`;

const MobileTitleCount = styled.span``;

const MobileToggleIcon = styled(FaIcon)``;

const FilterContent = styled.div`
    @media (max-width: ${({ theme }) => theme.breakpoints.tabletLandscapeMax}) {
        background-color: ${({ theme }) => theme.palette.white};
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        z-index: ${({ theme }) => theme.zIndex.dropdowns};
        opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
        visibility: ${({ isOpen }) => (isOpen ? `visible` : `hidden`)};
        border: ${({ theme }) => `1px solid ${theme.palette.black}`};
        border-top: 0;
        padding: 15px;
    }
`;

const FilterList = styled.ul`
    margin-left: ${({ isChildList }) => (isChildList ? `15px` : `0`)};
`;

const FilterParent = styled.div`
    font-size: 1.8rem;
`;

const StyledIconButton = styled(IconButton)`
    font-size: 1.6rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 15px;
    display: block;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
        font-size: 1.6rem;
    }
`;

const MoreLessButton = styled(IconButton)`
    font-size: 1.6rem;
    display: block;
    text-transform: uppercase;
    font-weight: 400;
    color: ${({ theme }) => theme.palette.black};
    line-height: 1;
    margin-top: 5px;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
        font-size: 1.6rem;
    }
`;

const reducer = (state, action) => {
    switch (action.type) {
        case "TOGGLE_IS_OPEN":
            return {
                ...state,
                isOpen: !state.isOpen,
            };
        default:
            return state;
    }
};

const sortFilterOptions = (unSortFilterOptions, typeName) => {
    if (typeName === "language") {
        return sortLanguageLinks(unSortFilterOptions);
    }

    return unSortFilterOptions;
};

const getNumberOfSelectedOptions = (
    isLanguageFilter,
    filterOptions,
    children,
    parentIncluded,
) => {
    if (isLanguageFilter) {
        return 0;
    }

    let filtersToUpdate = filterOptions;

    if (parentIncluded) {
        filtersToUpdate = [...filterOptions, ...children];
    }

    return reduce(
        filtersToUpdate,
        (acc, curr) => (curr.selected ? acc + 1 : acc),
        0,
    );
};

const FilterSection = ({
    title: unTranslatedTitle = "",
    filterOptions: unSortFilterOptions = [],
    filterOptionLimit = null,
    filtersToShowAll = [],
    typeName,
    handleClear,
    handleFilter,
    handleShowMoreToggle,
    isLanguageFilter = false,
}) => {
    const filterOptions = sortFilterOptions(unSortFilterOptions, typeName);
    const parentIncluded = filterOptions.some(({ isParent }) => isParent);
    const children = getFilterChildren(filterOptions);

    const numberOfOptionsSelected = getNumberOfSelectedOptions(
        isLanguageFilter,
        filterOptions,
        children,
        parentIncluded,
    );

    const shouldShowAllFilterOptions = filtersToShowAll.includes(typeName);

    const filterOptionsToDisplay = getFilterOptionsToDisplay(
        filterOptionLimit,
        shouldShowAllFilterOptions,
        filterOptions,
        parentIncluded,
    );

    const showLimitButtons = getShowLimitButtons(
        filterOptionLimit,
        children,
        filterOptions,
        parentIncluded,
    );

    const more = !shouldShowAllFilterOptions;

    const initialState = {
        isOpen: false,
    };

    const [state, dispatch] = useReducer(reducer, initialState);

    const strings = [
        { text: unTranslatedTitle, handle: "title" },
        { text: "More", handle: "moreText" },
        { text: "Less", handle: "lessText" },
        { text: "Clear All Filters", handle: "clearText" },
    ];

    const { title, moreText, lessText, clearText } =
        useStringTranslation(strings);

    return (
        <FilterWrapper
            id={`${typeName}-controls`}
            role="group"
            aria-labelledby={typeName}
        >
            {title ? <FilterTitle id={typeName}>{title}</FilterTitle> : null}
            {title ? (
                <MobileFilterToggle
                    type="button"
                    onClick={() => dispatch({ type: "TOGGLE_IS_OPEN" })}
                >
                    <MobileTitleCount>
                        {`${title}${
                            numberOfOptionsSelected
                                ? ` (${numberOfOptionsSelected})`
                                : ``
                        }`}
                    </MobileTitleCount>
                    <MobileToggleIcon
                        name={state.isOpen ? `chevron-up` : `chevron-down`}
                    />
                </MobileFilterToggle>
            ) : null}
            <FilterContent isOpen={state.isOpen}>
                <FilterList>
                    {filterOptionsToDisplay.map(
                        ({
                            name,
                            slug,
                            count,
                            selected,
                            isParent,
                            formattedChildren,
                            linkUrl,
                            setActiveCode,
                        }) => {
                            return isParent ? (
                                <li
                                    role="group"
                                    aria-labelledby={snakeCase(
                                        `${name}-${typeName}`,
                                    )}
                                    key={slug}
                                >
                                    <FilterParent
                                        id={snakeCase(`${name}-${typeName}`)}
                                    >
                                        {name}
                                    </FilterParent>
                                    <FilterList isChildList>
                                        {formattedChildren.map(
                                            ({
                                                name,
                                                slug,
                                                count,
                                                selected,
                                            }) => (
                                                <FilterListItem
                                                    key={slug}
                                                    {...{
                                                        name,
                                                        slug,
                                                        count,
                                                        selected,
                                                        handleFilter,
                                                        typeName,
                                                        isLanguageFilter,
                                                        linkUrl,
                                                        setActiveCode,
                                                    }}
                                                />
                                            ),
                                        )}
                                    </FilterList>
                                </li>
                            ) : (
                                <FilterListItem
                                    key={slug}
                                    {...{
                                        name,
                                        slug,
                                        count,
                                        selected,
                                        handleFilter,
                                        typeName,
                                        isLanguageFilter,
                                        linkUrl,
                                        setActiveCode,
                                    }}
                                />
                            );
                        },
                    )}
                </FilterList>

                {showLimitButtons ? (
                    <MoreLessButton
                        icon={more ? "plus" : "minus"}
                        onClick={() => handleShowMoreToggle(typeName)}
                        aria-expanded={!more}
                        aria-controls={`${typeName}-controls`}
                        aria-label={`${
                            more ? moreText : lessText
                        } ${title} filters`}
                    >
                        {more ? moreText : lessText}
                    </MoreLessButton>
                ) : null}

                {numberOfOptionsSelected ? (
                    <StyledIconButton
                        icon="window-close"
                        iconLibrary="far"
                        aria-label={`${clearText} - ${title}`}
                        onClick={() => handleClear(typeName)}
                    >
                        {clearText}
                    </StyledIconButton>
                ) : null}
            </FilterContent>
        </FilterWrapper>
    );
};

FilterSection.propTypes = {
    isLanguageFilter: PropTypes.bool,
    title: PropTypes.string,
    filterOptions: PropTypes.arrayOf(PropTypes.shape({})),
    typeName: PropTypes.string.isRequired,
    handleClear: PropTypes.func.isRequired,
    handleFilter: PropTypes.func.isRequired,
    filterOptionLimit: PropTypes.number,
    filtersToShowAll: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string,
    ]),
    handleShowMoreToggle: PropTypes.func.isRequired,
};

export default FilterSection;
